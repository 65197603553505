<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="logsType ? notificationLogsInst : notificationLogsAuto"
      :loading="loading"
      :options.sync="options"
      :server-items-length="logsType ? getNotificationLogsInstCount : getNotificationLogsAutoCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-container fluid>
          <v-row class="mt-2 mb-4">
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-datetime-picker
                v-model="fromDate"
                @input="paginateTo"
                :textFieldProps="textFieldProps"
                :datePickerProps="datePickerProps"
                :timePickerProps="timePickerProps"
                timeFormat="HH:mm:ss"
                dateFormat="dd-MM-yyyy"
                clear-text="очистити"
                ok-text="так"
                label="Дата та час від"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock-outline</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" offset-md="1">
              <v-datetime-picker
                v-model="toDate"
                @input="paginateTo"
                :textFieldProps="textFieldProps"
                :datePickerProps="datePickerProps"
                :timePickerProps="timePickerProps"
                dateFormat="dd-MM-yyyy"
                timeFormat="HH:mm:ss"
                clear-text="очистити"
                ok-text="так"
                label="Дата та час до"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock-outline</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:header.created_at="{ header }">
        <TableTitleDateFilter
          :title-text="header.text"
          @select="(selected) => (selectedDates = selected)"
          @ok="applyDateFilter('created_at', selectedDates)"
        />
      </template>
      <template v-slot:header.updated_at="{ header }">
        <TableTitleDateFilter
          :title-text="header.text"
          @select="(selected) => (selectedDates = selected)"
          @ok="applyDateFilter('updated_at', selectedDates)"
        />
      </template>
      <template v-slot:header.type="{ header }">
        <TableTitleFilter
          :values="selectedNotificationType"
          :options="pushNotificationTypesDictionary"
          :title-text="header.text"
          item-text="name"
          @select="(selected) => (selectedNotificationType = selected)"
          @ok="applyFilter('type', selectedNotificationType)"
        />
      </template>
      <template v-slot:header.is_one_time="{ header }">
        <TableTitleFilter
          :values="isOneTime"
          :options="oneTimeItems"
          :title-text="header.text"
          item-value="value"
          item-text="name"
          @select="(selected) => (isOneTime = selected)"
          @ok="applyFilter('is_one_time', isOneTime)"
        />
      </template>
      <template v-slot:header.initiator="{ header }">
        <TableTitleFilter
          :values="selectedInitiators"
          :options="initiators"
          :options-meta="initiatorsMeta"
          :options-params="initiatorsOptionsData"
          :title-text="header.text"
          item-value="id"
          @select="(selected) => (selectedInitiators = selected)"
          @ok="applyFilter('initiator.id', selectedInitiators)"
        >
          <template v-slot:item="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
          <template v-slot:selection="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
        </TableTitleFilter>
      </template>
      <template v-slot:header.balance_holder="{ header }">
        <TableTitleFilter
          :options="getBalanceHolderFilter"
          :title-text="header.text"
          @select="setSelectedBalanceHolders"
          @ok="applyFilter('balanceHolder', selectedBalanceHolders)"
        />
      </template>
      <template v-slot:header.service_point="{ header }">
        <TableTitleFilter
          :options="servicePoints"
          :title-text="header.text"
          @select="setSelectedServicePoints"
          @ok="applyFilter('servicePoint', selectedServicePoints)"
        />
      </template>
      <template v-slot:header.device="{ header }">
        <TableTitleFilter
          :options="connectedDevicesTypesDictionary"
          :title-text="header.text"
          item-value="name"
          item-text="description"
          @select="setSelectedDevicesType"
          @ok="applyFilter('deviceType', selectedDevicesTypes)"
        />
      </template>
      <template v-slot:header.device.id="{ header }">
        <TableTitleFilter
          :options="devices"
          :title-text="header.text"
          @select="setSelectedDevices"
          @ok="applyFilter('device', selectedDevices)"
        >
          <template v-slot:item="{ item }">{{ item.label }} ({{ item.type }}{{ item.value }})</template>
        </TableTitleFilter>
      </template>
      <template v-slot:header.receiver="{ header }">
        <TableTitleFilter
          :values="selectedReceivers"
          :options="users"
          :options-meta="usersMeta"
          :options-params="usersOptionsData"
          :title-text="header.text"
          item-value="id"
          @select="(selected) => (selectedReceivers = selected)"
          @ok="applyFilter('receiver.id', selectedReceivers)"
        >
          <template v-slot:item="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
          <template v-slot:selection="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
        </TableTitleFilter>
      </template>
      <template v-slot:item.id="{ item }">
        <router-link
          :to="{
            name: 'PushNotificationDelivery',
            params: { deliveryId: item.id },
            query: { event: item.event, settings: item.settings ? item.settings.id : null },
          }"
          class="text-decoration-none"
        >
          {{ item.id }}
        </router-link>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | getShortDate }}
        <sup>{{ item.created_at | getShortTime }}</sup>
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ item.updated_at | getShortDate }}
        <sup>{{ item.updated_at | getShortTime }}</sup>
      </template>
      <template v-slot:item.type="{ item }">{{ getNotificationType(item.type) }}</template>
      <template v-slot:item.is_one_time="{ item }">{{ item.is_one_time ? 'Миттєве' : 'Автоматичне' }}</template>
      <template v-slot:item.body="{ item }">
        <div v-html="item.body"></div>
      </template>
      <template v-slot:item.initiator="{ item }">{{ item.initiator.first_name }} {{ item.initiator.last_name }}</template>
      <template v-slot:item.company="{ item }">{{ item.company ? item.company.name : '' }}</template>
      <template v-slot:item.terminal="{ item }">
        <span v-if="item.terminal"> T{{ item.terminal.id }} </span>
      </template>
      <template v-slot:item.device="{ item }">
        {{
          item.device_type
            ? getItemTitle(machinesTypesDictionary, item.device_type)
            : item.device
            ? getItemTitle(machinesTypesDictionary, item.device.type)
            : ''
        }}
      </template>
      <template v-slot:item.receiver="{ item }">
        {{
          item.receiver
            ? `${item.receiver.first_name} ${item.receiver.last_name}`
            : getItemTitle(pushNotificationRecipientsDictionary, item.receiver_type)
        }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import sortUtils from '@/mixins/sortUtils'
import manageFiltersOptions from '@/mixins/manageFiltersOptions'
import TableTitleFilter from '@/components/common/filters/TableTitleFilter/TableTitleFilter.vue'
import convertDate from '@/mixins/convertDate'
import isEqual from 'lodash/isEqual'
import filterUtils from '@/mixins/filterUtils'
import TableTitleDateFilter from '@/components/common/filters/TableTitleFilter/TableTitleDateFilter.vue'
import convertToDictionary from '@/mixins/convertToDictionary'

export default {
  name: 'PushNotificationsLogs',
  components: { TableTitleDateFilter, TableTitleFilter },

  mixins: [sortUtils, filterUtils, manageFiltersOptions, convertDate, convertToDictionary],

  props: {
    presetIsOneTime: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['id'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      payload: {},
      isOneTime: [],
      selectedReceivers: [],
      selectedNotificationType: [],
      fromDate: null,
      toDate: null,
      textFieldProps: {
        hideDetails: 'auto',
        dense: true,
      },
      datePickerProps: {
        'first-day-of-week': 1,
      },
      timePickerProps: {
        format: '24hr',
        useSeconds: true,
      },
      oneTimeItems: [
        { value: 0, name: 'Автоматичне' },
        { value: 1, name: 'Миттєве' },
      ],
    }
  },

  created() {
    this.initialize()
  },

  watch: {
    options: {
      handler(val, old) {
        if (!isEqual(val, old)) {
          this.paginateTo()
        }
      },
      deep: true,
    },

    presetIsOneTime(val, old) {
      if (!isEqual(val, old)) {
        this.isOneTime = val.map((item) => this.oneTimeItems[item])
        this.applyFilter('is_one_time', this.isOneTime)
      }
    },
  },

  computed: {
    ...mapState('pushNotification', [
      'notificationLogsAuto',
      'notificationLogsAutoMeta',
      'notificationLogsInst',
      'notificationLogsInstMeta',
    ]),
    ...mapState('dictionaries', [
      'pushNotificationTypesDictionary',
      'connectedDevicesTypesDictionary',
      'machinesTypesDictionary',
      'pushNotificationRecipientsDictionary',
    ]),
    ...mapGetters('pushNotification', ['getNotificationLogsAutoCount', 'getNotificationLogsInstCount']),
    ...mapGetters('companies', [
      'getUserFilter',
      'getBalanceHolderFilter',
      'getServicePointFilter',
      'getTerminalFilter',
      'getDeviceFilter',
    ]),

    headers() {
      return [
        { text: 'ID', value: 'id' },
        { text: 'Створено', value: 'created_at', width: 105 },
        { text: 'Оновлено', value: 'updated_at', width: 105 },
        { text: 'Тип повідомлення', value: 'type' },
        { text: 'Ознака', value: 'is_one_time' },
        { text: this.logsType ? 'Назва повідомлення' : 'Назва розсилки', value: 'title' },
        { text: this.logsType ? 'Текст повідомлення' : 'Текст розсилки', value: 'body' },
        { text: 'Ініціатор', value: 'initiator', sortFields: ['initiator.last_name', 'initiator.first_name'] },
        { text: 'Компанія', value: 'company' },
        { text: 'Орендодавець', value: 'balance_holder.name' },
        { text: 'Торгівельна точка', value: 'service_point.name' },
        { text: 'Т', value: 'terminal' },
        { text: 'Тип А', value: 'device' },
        { text: 'ID А', value: 'device.id' },
        { text: '№ А', value: 'device.connection_number' },
        { text: 'Отримувач', value: 'receiver', sortFields: ['receiver.last_name', 'receiver.first_name'] },
        { text: 'Кількість відправлених повідомлень', value: 'receiver_quantity' },
        { text: 'Доставлено', value: 'received_quantity' },
        { text: 'Прочитано', value: 'read_quantity' },
      ]
    },

    currentCompanyId() {
      return this.$route.params.id
    },

    logsType() {
      return this.presetIsOneTime[0]
    },
  },

  methods: {
    ...mapActions('pushNotification', ['loadPushNotificationsLogs']),

    async initialize() {
      this.isOneTime = this.presetIsOneTime.map((item) => this.oneTimeItems[item])
      this.isOneTime = this.presetIsOneTime.map((item) => this.oneTimeItems[item])
      await this.applyFilter('is_one_time', this.isOneTime)
    },

    async paginateTo() {
      this.payload = {
        ...this.payload,
        page: this.options.page,
        limit: this.options.itemsPerPage,
        sort: this.sortObject,
        updatedAt: {
          fromDate: this.fromDate,
          toDate: this.toDate,
        },
        logsType: this.logsType,
        ...this.filterObject,
      }
      this.loading = true
      try {
        await this.loadPushNotificationsLogs(this.payload)
      } finally {
        this.loading = false
      }
    },

    getNotificationType(value) {
      return this.pushNotificationTypesDictionary.find((item) => item.value === value)?.name || ''
    },
  },
}
</script>
